import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import Alert from '../../components/Alert/Alert'
import SEO from '../../components/seo'
import LinkList from '../../components/LinkList/LinkList'

const BackgroundersPage = ({ data }) => {
  const intl = useIntl()

  const backgroundersArr = data.backgrounders.edges.map((link) => ({
    path: `/news-and-events/backgrounders/${link.node.frontmatter.slug}`,
    text: link.node.frontmatter.title,
    internal: true
  }))

  return (
    <Layout
      header={
        <span className='bold'>
          <FormattedMessage id='nav.backgrounders' />
        </span>
      }
      breadcrumb={{
        text: <FormattedMessage id='nav.news' />,
        path: '/news-and-events/'
      }}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.backgrounders' })}
      />
      <Container>
        <Row align='stretch'>
          <Col>
            {intl.locale === 'fr' && (
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            )}
            <LinkList links={backgroundersArr} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default BackgroundersPage

export const pageQuery = graphql`
  query BackgroundersQuery {
    backgrounders: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/backgrounders/" } }
      sort: { fields: frontmatter___title, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`
